var Handlebars = require("../../../../../srv/zulip-npm-cache/0a30d4aa9b2af668449e2ccdeca9926089cfbb8e/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div data-full-path=\""
    + alias4(((helper = (helper = helpers.full_path || (depth0 != null ? depth0.full_path : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"full_path","hash":{},"data":data}) : helper)))
    + "\" data-line-no=\""
    + alias4(((helper = (helper = helpers.line_number || (depth0 != null ? depth0.line_number : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"line_number","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"stackframe\">\n            <i class=\"fa fa-caret-right expand\"></i>\n            <span class=\"subtle\">at</span>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.function_name : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <span class=\"subtle\">"
    + alias4(((helper = (helper = helpers.show_path || (depth0 != null ? depth0.show_path : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"show_path","hash":{},"data":data}) : helper)))
    + ":"
    + alias4(((helper = (helper = helpers.line_number || (depth0 != null ? depth0.line_number : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"line_number","hash":{},"data":data}) : helper)))
    + "</span>\n        </div>\n        <div class=\"code-context\" style=\"display: none\">\n            <div class=\"code-context-content\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.context : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.function_name : depth0)) != null ? stack1.scope : stack1), depth0))
    + "<b>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.function_name : depth0)) != null ? stack1.name : stack1), depth0))
    + "</b>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.focus : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "><span class=\"line-number\">"
    + alias4(((helper = (helper = helpers.line_number || (depth0 != null ? depth0.line_number : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"line_number","hash":{},"data":data}) : helper)))
    + "</span> "
    + alias4(((helper = (helper = helpers.line || (depth0 != null ? depth0.line : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"line","hash":{},"data":data}) : helper)))
    + "</div>";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"focus-line\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"stacktrace-header\">\n    <div class=\"warning-symbol\">\n        <i class=\"fa fa-exclamation-triangle\"></i>\n    </div>\n    <div class=\"message\"><strong>Error:</strong> "
    + container.escapeExpression(((helper = (helper = helpers.error || (depth0 != null ? depth0.error : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"error","hash":{},"data":data}) : helper)))
    + "</div>\n    <div class=\"exit\"></div>\n</div>\n<div class=\"stacktrace-content\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.stackframes : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});